// src/App.tsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './component/Navbar';
import PdLogos from "./component/PdLogosPage";

const Home: React.FC = () => <h1>Pen Design Showcase Website - v1</h1>;
const About: React.FC = () => <h1>About Us Page</h1>;
const Contact: React.FC = () => <h1>Contact Page</h1>;
const Login: React.FC = () => <h1>Login Pages</h1>;

const App: React.FC = () => {
    return (
        <div>
            <Navbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/login" element={<Login />} />
                <Route path="/logos" element={<PdLogos />} /> {/* Adding the new route here */}
            </Routes>
        </div>
    );
};

export default App;