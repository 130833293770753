import React from 'react';
import './PictureGallery.css'; // Ensure this import matches your actual CSS file for styles

const imagePaths = [
    'https://pendesign.art/assets/images/logo_1.webp',
    'https://pendesign.art/assets/images/logo_2.jpg',
    'https://pendesign.art/assets/images/logo_3.jpg',
    'https://pendesign.art/assets/images/logo_4.jpg',
    'https://pendesign.art/assets/images/logo_5.jpg',
    'https://pendesign.art/assets/images/logo_6.jpg',
    'https://pendesign.art/assets/images/logo_7.jpg',
    'https://pendesign.art/assets/images/logo_8.jpg',
    'https://pendesign.art/assets/images/logo_9.jpg',
    'https://pendesign.art/assets/images/logo_10.jpg',
    'https://pendesign.art/assets/images/logo_11.jpg',
    'https://pendesign.art/assets/images/logo_12.jpg',
    'https://pendesign.art/assets/images/logo_13.jpg',
    'https://pendesign.art/assets/images/logo_14.jpg',
    'https://pendesign.art/assets/images/logo_15.jpg',
    'https://pendesign.art/assets/images/logo_16.jpg',
    'https://pendesign.art/assets/images/logo_17.jpg',
    'https://pendesign.art/assets/images/logo_18.jpg',
    'https://pendesign.art/assets/images/logo_19.jpg',
    'https://pendesign.art/assets/images/logo_20.jpg',
    'https://pendesign.art/assets/images/logo_21.jpg',
    'https://pendesign.art/assets/images/logo_22.jpg',
    'https://pendesign.art/assets/images/logo_23.jpg',
    'https://pendesign.art/assets/images/logo_24.jpg',
    'https://pendesign.art/assets/images/logo_25.jpg',
    'https://pendesign.art/assets/images/logo_26.jpg',
    'https://pendesign.art/assets/images/logo_27.jpg',
    'https://pendesign.art/assets/images/logo_28.jpg',
    'https://pendesign.art/assets/images/logo_29.webp',
    'https://pendesign.art/assets/images/logo_30.jpg',
];

const PdLogos: React.FC = () => {
    return (
        <div>
            <h1>Pen Design Logos Page</h1>
            <p>Welcome to the PdLogos page!</p>
            <div className="pictures-gallery">
                {imagePaths.map((path, index) => (
                    <div key={index} className="picture-item">
                        <img src={path} alt={`Logo ${index + 1}`} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PdLogos;